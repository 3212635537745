import { ApolloClient, ApolloLink, concat, createHttpLink, DocumentNode, FetchPolicy, gql, InMemoryCache } from '@apollo/client';
import { GraphqlClient } from '@diegodfreire/sade-gql-client-js';
import env from 'config/env';
import { GraphQLError } from 'graphql';
import { isArray, isBoolean } from 'lodash';
import { removeTypename } from 'utils';

import { makeUserData } from '../../main/factories/user';

interface ObjectLiteral {
  [key: string]: any;
}

interface IVariablesMutation {
  data?: ObjectLiteral;
}

const userData = () => makeUserData();

const httpLink = createHttpLink({
  uri: `${env.API_URL}/graphql`,
  credentials: 'include',
}).setOnError(() => {
  window.location.href = '/login';
});

const authMiddleware = new ApolloLink((operation, forward) => {
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      Authorization: `Bearer ${userData().getToken()}` || null,
    },
  }));

  return forward(operation);
});

const apolloClient = new ApolloClient({
  link: concat(httpLink, authMiddleware),
  cache: new InMemoryCache({}),
});

class SadeGraphQLClient extends GraphqlClient {
  constructor() {
    super({
      url: `${env.API_URL}/graphql`,
      httpClient: {} as any,
    });
  }
  executeQuery = async <T, Q extends ObjectLiteral>(queryString: string, variables: Q, cache: string = 'no-cache') => {
    const finalVariable = Object.keys(variables).reduce((curr, key) => {
      if ((!isBoolean(variables[key]) && !variables[key]) || (isArray(variables[key]) && !variables[key].length)) {
        return { ...curr };
      }
      return { ...curr, [key]: variables[key] };
    }, {});
    const { data, errors } = await apolloClient.query<T>({
      query: gql`
        ${queryString}
      `,
      variables: { ...finalVariable },
      context: {
        headers: {
          Authorization: `Bearer ${userData().getToken()}`,
          'x-tenant-host': env.TENANT_HOST,
        },
      },
      errorPolicy: 'all',
      fetchPolicy: cache as FetchPolicy,
    });
    logError(errors);
    if (!isAuthenticated(errors)) {
      redirectToLogin();
    }
    const modelData = removeTypename(data as any);
    return { ...(modelData as T), errors };
  };
  executeMutation = async <T>(mutation: string, variables: IVariablesMutation) => {
    const payload = removeTypename(variables);
    const { data, errors } = await apolloClient.mutate({
      mutation: gql(mutation),
      variables: { ...payload },
      context: {
        headers: {
          Authorization: `Bearer ${userData().getToken()}`,
          'x-tenant-host': env.TENANT_HOST,
        },
      },
      errorPolicy: 'all',
      fetchPolicy: 'no-cache',
    });
    logError(errors);
    if (!isAuthenticated(errors)) {
      redirectToLogin();
    }

    return { ...(data as T), errors };
  };
}

const sadeGqlClient = new SadeGraphQLClient();

const isAuthenticated = (errors: readonly GraphQLError[] | undefined) => {
  if (errors) {
    const unauthorizedError = errors.find((e) => e.message === 'Unauthorized');
    console.error({ errors, unauthorizedError });
    return !unauthorizedError;
  }
  return true;
};

const logError = (errors: readonly GraphQLError[] | undefined) => {
  if (errors && errors.length) {
    console.error(errors);
  }
};

const redirectToLogin = () => {
  return (window.location.href = '/login');
};

export const executeQuery = async <T, Q extends ObjectLiteral>(query: DocumentNode, variables: Q, cache: FetchPolicy = 'no-cache') => {
  const finalVariable = Object.keys(variables).reduce((curr, key) => {
    if ((!isBoolean(variables[key]) && !variables[key]) || (isArray(variables[key]) && !variables[key].length)) {
      return { ...curr };
    }
    return { ...curr, [key]: variables[key] };
  }, {});
  const { data, errors } = await apolloClient.query<T>({
    query: query,
    variables: { ...finalVariable },
    context: {
      headers: {
        Authorization: `Bearer ${userData().getToken()}`,
        'x-tenant-host': env.TENANT_HOST,
      },
    },
    errorPolicy: 'all',
    fetchPolicy: cache,
  });
  logError(errors);
  if (!isAuthenticated(errors)) {
    redirectToLogin();
  }
  const modelData = removeTypename(data as any);
  return { ...(modelData as T), errors };
};

export const executeMutation = async <T>(mutation: DocumentNode, variables: IVariablesMutation) => {
  const payload = removeTypename(variables);
  const { data, errors } = await apolloClient.mutate({
    mutation: mutation,
    variables: { ...payload },
    context: {
      headers: {
        Authorization: `Bearer ${userData().getToken()}`,
        'x-tenant-host': env.TENANT_HOST,
      },
    },
    errorPolicy: 'all',
    fetchPolicy: 'no-cache',
  });
  logError(errors);
  if (!isAuthenticated(errors)) {
    redirectToLogin();
  }

  return { ...(data as T), errors };
};

export default apolloClient;
export { sadeGqlClient };
