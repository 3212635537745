import dayjs from 'dayjs';
import parser from 'dayjs/plugin/customParseFormat';
import tz from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);
dayjs.extend(tz);
dayjs.extend(parser);
dayjs.locale('pt-br');

const dayjsAdapter: (date?: dayjs.ConfigType, format?: dayjs.OptionType, locale?: string | boolean, strict?: boolean) => dayjs.Dayjs = dayjs;

const createDate = (date?: dayjs.ConfigType) => {
  return dayjs(date || new Date()).tz('America/Sao_Paulo');
};

export { createDate, dayjsAdapter };
export default dayjsAdapter;
